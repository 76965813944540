













































import { Component, Vue } from 'vue-property-decorator';

/**
 * Footer component
 * 
 * @author Kevin Danne <danne@skiba-procomputer.de>
 */
@Component
export default class Footer extends Vue {
}
